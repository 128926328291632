import React from 'react';

class Test extends React.Component {
    render() {
        return(
            <div>
                <h1>Hi</h1>
                <p>Some text from the API will go here</p>
            </div>
        );
    }
}

export default Test;